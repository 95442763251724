const lang: any = {
  fr: {
    fr: "FR",
    ar: "فر",
    en: "FR",
  },
  ar: {
    fr: "AR",
    ar: "عر",
    en: "AR",
  },
  en: {
    fr: "EN",
    ar: "إنج",
    en: "EN",
  },
};
export default lang;

"use client";
import React, { useEffect, useRef, useState } from "react";
import Image from "next/image";
import Link from "next/link";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/cn";
import { MdKeyboardArrowDown } from "react-icons/md";

import navbarTranslation from "@/translation/navbarTranslation";
import { TransitionLink } from "./TransitionLink";
import { useRouter } from "next/navigation";
interface NavLinkProps {
  href: string;
  title: string;
  dropDown: any;
  hasDropDown?: boolean;
  lang: string;
}

const NavLink = ({
  href,
  title,
  dropDown,
  hasDropDown,
  lang,
}: NavLinkProps) => {
  const [isActive, setIsActive] = useState(false);
  const router = useRouter();
  const ref = useRef<HTMLDivElement>(null);
  const handleClickOutside = (event: any) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsActive(false);
    }
  };

  useEffect(() => {
    // Add event listener to document when component mounts
    document.addEventListener("mousedown", handleClickOutside);
    document.addEventListener("scroll", handleClickOutside);
    return () => {
      // Clean up the event listener when component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
      document.removeEventListener("scroll", handleClickOutside);
    };
  }, []);
  return (
    <motion.div
      className="relative drop"
      ref={ref}
      onMouseEnter={() => hasDropDown && setIsActive(true)}
      onMouseLeave={() => hasDropDown && setIsActive(false)}
    >
      <TransitionLink
        href={href}
        className={cn(
          "text-white text-sm relative flex items-center justify-center gap-1 p-1 px-2 rounded-full transition-all duration-300 hover:text-black hover:bg-white/90",
          isActive ? "text-black bg-white/90" : ""
        )}
        onClick={async (e) => {
          hasDropDown && e.preventDefault();
        }}
      >
        <span className="relative">{navbarTranslation[title][lang]}</span>
        {hasDropDown && (
          <MdKeyboardArrowDown
            className={cn("transition-transform", isActive ? "rotate-180" : "")}
          />
        )}
      </TransitionLink>

      <AnimatePresence>
        {dropDown && isActive && (
          <motion.div
            initial={{ opacity: 0.2, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: -10 }}
            className={cn(
              "min-w-[600px] min-h-32 z-1000 p-6 bg-neutral-900 border border-white/10 bg-gradient-to-br from-black/5 to-black/30 rounded-xl absolute top-[180%]  grid gap-4",
              lang === "ar" && "left-auto -right-24"
            )}
            style={{
              gridTemplateColumns:
                dropDown.length > 1
                  ? dropDown.length === 2
                    ? "1fr 1fr"
                    : "1fr 1fr 1fr"
                  : "1fr",
              minWidth: dropDown.length > 1 ? 600 : 300,
              left: dropDown.length > 1 ? "-100%" : 0,
            }}
          >
            <div className="left-0 right-0 -top-6 h-8 absolute bg-transparent"></div>
            {dropDown?.map((drop: any, index: number) => {
              return (
                <div key={Date.now() + index}>
                  {navbarTranslation[drop?.title] && (
                    <h3 className="text-lg mb-4">
                      {navbarTranslation[drop?.title].fr}
                    </h3>
                  )}
                  <div className="flex flex-col gap-4">
                    {drop.links.map((l: any) => {
                      return (
                        <TransitionLink
                          href={l.href}
                          className="flex items-center gap-2 cursor-pointer hover:bg-neutral-800 rounded transition-colors p-2"
                          key={l.href}
                        >
                          {l.image && (
                            <Image
                              src={l.image}
                              className="w-auto h-14 rounded object-cover"
                              alt="product image"
                            />
                          )}
                          <span className="text-sm">
                            {navbarTranslation[l.title][lang]}
                          </span>
                        </TransitionLink>
                      );
                    })}
                  </div>
                </div>
              );
            })}
          </motion.div>
        )}
      </AnimatePresence>
    </motion.div>
  );
};

export default NavLink;

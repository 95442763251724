import React, { useEffect, useRef, useState, memo, useContext } from "react";
import Image from "next/image";
import franceFlag from "@/assets/fr-flag.svg";
import moroccoFlag from "@/assets/mr-flag.png";
import usaFlag from "@/assets/usa-flag.png";
import downFilled from "@/assets/down-filled.svg";
import { languageContext } from "@/contexts/language-context";
import togglerTranslation from "@/translation/languageToggler";
import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/cn";
type langType = {
  lang: string;
  setLang: (lang: string) => void;
};

const FlagButton = ({
  lang,
  currentLang,
  selectLanguage,
}: {
  lang: string;
  currentLang: string;
  selectLanguage: (lang: string) => void;
}) => {
  return (
    <button
      className="flex items-center bg-[#d4d4d4] bg-opacity-80 text-base ml-auto border border-white rounded-[0.25rem] px-2 gap-2 w-16 h-8 mt-1"
      onClick={() => selectLanguage(lang)}
    >
      <Image
        src={lang === "fr" ? franceFlag : lang === "en" ? usaFlag : moroccoFlag}
        alt={""}
        width={16}
      />
      <span>{togglerTranslation[lang][currentLang]}</span>
    </button>
  );
};

const LanguageSelect = ({ className }: { className?: string }) => {
  const { lang, setLang }: any = useContext<langType | null>(languageContext);
  const [open, setOpen] = useState(false);
  const selectLanguage = (lang: string) => {
    setLang(lang);
    setOpen(false);
  };
  return (
    <div className={cn("ms-4", className)}>
      <button
        className="bg-transparent bg-opacity-80 text-base flex justify-around gap-2 ml-auto border border-gray-500 rounded-[0.25rem] items-center w-16 h-8 px-2"
        onClick={() => {
          setOpen(!open);
        }}
      >
        <Image
          src={
            lang === "fr" ? franceFlag : lang === "en" ? usaFlag : moroccoFlag
          }
          alt={""}
          width={16}
        />
        <div className="flex items-center gap-1">
          <span>{togglerTranslation[lang][lang]}</span>
          <Image
            className={open ? "rotate-180" : ""}
            src={downFilled}
            alt={""}
          />
        </div>
      </button>
      <AnimatePresence>
        {open && (
          <>
            <motion.div
              className="absolute"
              initial={{ y: -20, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
            >
              {["fr", "ar", "en"]
                .filter((l) => l !== lang)
                .map((l, i) => {
                  return (
                    <FlagButton
                      lang={l}
                      currentLang={lang}
                      selectLanguage={selectLanguage}
                      key={"lang" + i}
                    />
                  );
                })}
            </motion.div>
          </>
        )}
      </AnimatePresence>
    </div>
  );
};

export default memo(LanguageSelect);

const navbar: any = {
  accuiel: {
    fr: "Accueil",
    ar: "الصفحة الرئيسية",
    en: "Home",
  },
  nosOffres: {
    fr: "Nos offres",
    ar: "عروضنا",
    en: "Our Offers",
  },
  commentCaMarche: {
    fr: "Comment ça Marche",
    ar: "كيفية الإستخدام ؟",
    en: "How it Works",
  },
  actualites: {
    fr: "Actualités",
    ar: "المدونات",
    en: "News",
  },
  contact: {
    fr: "Contact",
    ar: "الاتصال بنا",
    en: "Contact",
  },
  acheterMaPopcard: {
    fr: "Acheter ma popcard",
    ar: "إشتري بطاقتك",
    en: "Buy my Popcard",
  },
  professionnels: {
    fr: "Professionnels",
    ar: "خبراء",
    en: "Professionals",
  },
  enterprise: {
    fr: "Entreprises",
    ar: "الشركات",
    en: "Companies",
  },
  popcardStandard: {
    fr: (
      <>
        Popcard Pro
        <br />
        Standard
      </>
    ),
    ar: (
      <>
        Popcard Pro
        <br />
        Standard
      </>
    ),
    en: (
      <>
        Popcard Pro
        <br />
        Standard
      </>
    ),
  },
  popcardPersonalise: {
    fr: "Popcard Pro Personnalisée",
    ar: "Popcard Pro Personnalisée",
    en: "Popcard Pro Customized",
  },
  popcardReviews: {
    fr: "Popcard Reviews",
    ar: "Popcard Reviews",
    en: "Popcard Reviews",
  },
  popcardMetrics: {
    fr: "Popcard Metrics",
    ar: "Popcard Metrics",
    en: "Popcard Metrics",
  },
  coffrets: {
    fr: "Coffrets cadeaux",
    ar: "Coffrets cadeaux",
    en: "Gift Boxes",
  },
  popcard: {
    fr: "Popcard",
    ar: "Popcard",
    en: "Popcard",
  },
  popcardRewards: {
    fr: "Popcard Rewards",
    ar: "Popcard Rewards",
    en: "Popcard Rewards",
  },
  rewards: {
    fr: "Rewards",
    ar: "Rewards",
    en: "Rewards",
  },
};

export default navbar;

"use client";
import React, { useContext } from "react";
import Image from "next/image";
import PopcardLogo from "@/assets/logo-popcard.svg";
import Link from "next/link";

// icons
import { FaFacebook } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import { FaInstagram } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa";
import Container from "./ui/Container";
import { cn } from "@nextui-org/theme";
import { languageContext } from "@/contexts/language-context";
import footerTranslation from "@/translation/footerTanslation";

const PopcardColumn = [
  { title: footerTranslation.accuiel, href: "/" },
  { title: footerTranslation.nosOffres, href: "/order" },
  { title: footerTranslation.commentCaMarche, href: "/comment-ca-marche" },
  //{ title: footerTranslation.actualites, href: "/actualites" },
  { title: footerTranslation.contact, href: "/contact" },
];

const LegalColumn = [
  { title: footerTranslation.mentionLegal, href: "/mentions-legales" },
  { title: footerTranslation.cgv, href: "/cgv" },
  {
    title: footerTranslation.politiqueDeConfidentialite,
    href: "/politique-de-confidentialite",
  },
];

const CommanderColumn = [
  {
    title: footerTranslation.popcardStandard,
    href: "/commande-popcard-pro-standard",
  },
  {
    title: footerTranslation.popcardPersonalise,
    href: "/commande-popcard-pro-personalise",
  },
  {
    title: footerTranslation.popcardMetrics,
    href: "/commande-popcard-entreprise",
  },
  {
    title: footerTranslation.popcardReviews,
    href: "/commande-popcard-reviews",
  },
  {
    title: footerTranslation.coffretCadeaux,
    href: "/order#coffrets",
  },
  {
    title: footerTranslation.popcardRewards,
    href: "/rewards",
    style:
      "bg-gradient-to-r from-blue-400 to-red-500 bg-clip-text text-transparent",
  },
];

const Footer = () => {
  const { lang } = useContext<any>(languageContext);
  return (
    <footer className="w-full py-[5rem] bg-neutral-950">
      <Container className="">
        <div className="grid  grid-cols-1 sm:grid-cols-4 gap-8">
          <div className="flex flex-col items-center sm:items-start justify-between w-full">
            <Image src={PopcardLogo} alt="Popcard's Logo" height={35} />
            <div className="w-full items-center justify-start gap-6 hidden sm:flex">
              <Link
                href="https://www.facebook.com/MyPopcard/"
                className="hover:opacity-80 transition-opacity"
                aria-label="facebook-link"
              >
                <FaFacebook size={25} />
              </Link>
              <Link
                href="https://www.linkedin.com/company/getpopcard/"
                className="hover:opacity-80 transition-opacity"
                aria-label="linkedin-link"
              >
                <FaLinkedin size={25} />
              </Link>
              <Link
                href="https://www.instagram.com/get.popcard/"
                className="hover:opacity-80 transition-opacity"
                aria-label="instagram-link"
              >
                <FaInstagram size={25} />
              </Link>
              <Link
                href="https://www.youtube.com/shorts/BqC21lo7b2c"
                className="hover:opacity-80 transition-opacity"
                aria-label="youtube-link"
              >
                <FaYoutube size={25} />
              </Link>
            </div>
          </div>
          <FooterColumn
            name={footerTranslation.popcard[lang]}
            links={PopcardColumn}
            lang={lang}
          />
          <FooterColumn
            name={footerTranslation.legal[lang]}
            links={LegalColumn}
            lang={lang}
          />
          <FooterColumn
            name={footerTranslation.commander[lang]}
            links={CommanderColumn}
            lang={lang}
          />
        </div>

        <div className="w-full items-center justify-center mt-8 gap-6 flex sm:hidden">
          <Link
            href="https://www.facebook.com/MyPopcard/"
            className="hover:opacity-80 transition-opacity"
            aria-label="faceboook-link"
          >
            <FaFacebook size={20} />
          </Link>
          <Link
            href="https://www.linkedin.com/company/getpopcard/"
            className="hover:opacity-80 transition-opacity"
            aria-label="linkedin-link"
          >
            <FaLinkedin size={20} />
          </Link>
          <Link
            href="https://www.instagram.com/get.popcard/"
            className="hover:opacity-80 transition-opacity"
            aria-label="instagram-link"
          >
            <FaInstagram size={20} />
          </Link>
          <Link
            href="https://www.youtube.com/shorts/BqC21lo7b2c"
            className="hover:opacity-80 transition-opacity"
            aria-label="youtube-link"
          >
            <FaYoutube size={20} />
          </Link>
        </div>

        <p className="text-light-gray text-[11px] sm:text-sm mt-12 text-center max-w-[300px] sm:max-w-full mx-auto">
          {footerTranslation.right[lang]}
        </p>
      </Container>
    </footer>
  );
};

const FooterLink = ({
  href,
  title,
  style,
  lang,
}: {
  href: string;
  title: any;
  style?: string;
  lang: string;
}) => {
  return (
    <Link
      href={href}
      className={cn(
        "text-sm sm:text-md font-regular text-neutral-300 hover:text-light-gray transition-colors",
        style || ""
      )}
    >
      {title[lang]}
    </Link>
  );
};

interface FooterLinkProp {
  title: string;
  href: string;
}

const FooterColumn = ({
  name,
  links,
  lang,
}: {
  name: string;
  links: FooterLinkProp[];
  lang: string;
}) => {
  return (
    <div>
      <h3
        className={cn(
          "font-medium text-xl sm:text-2xl mb-8 tracking-wide text-center sm:text-left",
          lang === "ar" && "sm:text-right"
        )}
      >
        {name}
      </h3>

      <div className="flex flex-col items-center sm:items-start gap-6 sm:gap-8">
        {links.map(
          (
            l: { href: string; title: string; style?: string },
            index: number
          ) => {
            return (
              <FooterLink
                lang={lang}
                href={l.href}
                title={l.title}
                style={l.style}
                key={index}
              />
            );
          }
        )}
      </div>
    </div>
  );
};
export default Footer;

"use client";
import React, { createContext, useContext, useState } from "react";

const CheckoutContext = createContext<any>(null);

export const useCheckout = () => {
  const { checkout, setCheckout } = useContext(CheckoutContext);
  return [checkout, setCheckout];
};

export const CheckoutProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [checkout, setCheckout] = useState<any>({});

  return (
    <CheckoutContext.Provider value={{ checkout, setCheckout }}>
      {children}
    </CheckoutContext.Provider>
  );
};

const footerTranslation: any = {
  accuiel: {
    fr: "Accueil",
    ar: "الصفحة الرئيسية",
    en: "Home",
  },
  nosOffres: {
    fr: "Nos offres",
    ar: "عروضنا",
    en: "Our Offers",
  },
  commentCaMarche: {
    fr: "Comment ça Marche",
    ar: "كيفية الإستخدام ؟",
    en: "How it Works",
  },
  actualites: {
    fr: "Actualités",
    ar: "المدونات",
    en: "News",
  },
  popcard: {
    fr: "Popcard",
    ar: "Popcard",
    en: "Popcard",
  },
  legal: {
    fr: "Legal",
    ar: "الشؤون القانونية",
    en: "Legal",
  },
  commander: {
    fr: "Commander",
    ar: "اطلب",
    en: "Order",
  },
  mentionLegal: {
    fr: "Mentions Légales",
    ar: "إشعارات قانونية",
    en: "Legal Notices",
  },
  cgv: {
    fr: "CGV",
    ar: "الشروط العامة للبيع",
    en: "Terms of Sale",
  },
  politiqueDeConfidentialite: {
    fr: "Politique de Confidentialité",
    ar: "سياسة الخصوصية",
    en: "Privacy Policy",
  },
  popcardStandard: {
    fr: "Popcard Pro Standard",
    ar: "Popcard Pro Standard",
    en: "Popcard Pro Standard",
  },
  popcardPersonalise: {
    fr: "Popcard Pro Personnalisée",
    ar: "Popcard Pro Personnalisée",
    en: "Popcard Pro Customized",
  },
  popcardReviews: {
    fr: "Popcard Reviews",
    ar: "Popcard Reviews",
    en: "Popcard Reviews",
  },
  popcardMetrics: {
    fr: "Popcard Metrics",
    ar: "Popcard Metrics",
    en: "Popcard Metrics",
  },
  popcardRewards: {
    fr: "Popcard Rewards",
    ar: "Popcard Rewards",
    en: "Popcard Rewards",
  },
  contact: {
    fr: "Contact",
    ar: "اتصال",
    en: "Contact",
  },
  right: {
    fr: "© Popcard 2022 - Marque déposée par Amphitech SARL - Tous droits réservés",
    ar: "جميع الحقوق محفوظة للعلامة التجارية Amphitech SARL - Popcard 2022 ©",
    en: "© Popcard 2022 - Trademark by Amphitech SARL - All rights reserved",
  },
  coffretCadeaux: {
    fr: "Coffrets cadeaux",
    ar: "Coffrets cadeaux",
    en: "Gift Boxes",
  },
};

export default footerTranslation;

import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/popcard/PRODUCTION/getpopcard.com/components/footer.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/popcard/PRODUCTION/getpopcard.com/components/main-navbar.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CheckoutProvider"] */ "/home/popcard/PRODUCTION/getpopcard.com/contexts/checkout-context.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["LanguageProvider"] */ "/home/popcard/PRODUCTION/getpopcard.com/contexts/language-context.tsx");
;
import(/* webpackMode: "eager" */ "/home/popcard/PRODUCTION/getpopcard.com/node_modules/@next/third-parties/dist/google/ga.js");
;
import(/* webpackMode: "eager" */ "/home/popcard/PRODUCTION/getpopcard.com/node_modules/@next/third-parties/dist/google/gtm.js");
;
import(/* webpackMode: "eager" */ "/home/popcard/PRODUCTION/getpopcard.com/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
;
import(/* webpackMode: "eager", webpackExports: ["NextUIProvider"] */ "/home/popcard/PRODUCTION/getpopcard.com/node_modules/@nextui-org/system/dist/index.mjs");
;
import(/* webpackMode: "eager" */ "/home/popcard/PRODUCTION/getpopcard.com/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/home/popcard/PRODUCTION/getpopcard.com/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/popcard/PRODUCTION/getpopcard.com/styles/globals.css");

"use client";
import React, { useContext, useEffect, useState } from "react";
import Image from "next/image";
import PopcardLogo from "@/assets/logo-popcard.svg";
import Link from "next/link";
import NavLink from "./ui/nav-link";
import ButtonLink from "@/components/ui/button-link";
import Container from "./ui/Container";
import { CgMenuRightAlt } from "react-icons/cg";
import { IoCloseSharp } from "react-icons/io5";
import { MdKeyboardArrowDown } from "react-icons/md";
import { MdChevronRight } from "react-icons/md";

import StandardSrc from "@/assets/products/standard.png";
import PersonaliseSrc from "@/assets/products/personalise.png";
// import ReviewsSrc from "@/assets/products/reviews.png";
import ReviewsSrc from "@/assets/products/backReviews.png";
import MetricsSrc from "@/assets/products/metrics.png";
import RewardsSrc from "@/assets/products/rewards.png";
import CouffretSrc from "@/assets/cofferts/coffret-essentiel-premium.jpg";

import { AnimatePresence, motion } from "framer-motion";
import { cn } from "@/lib/cn";
import LanguageToggler from "./ui/language-toggler";
import { languageContext } from "@/contexts/language-context";
import navbarTranslation from "@/translation/navbarTranslation";
import { TransitionLink } from "./ui/TransitionLink";

const NAV_LINKS = [
  {
    title: "accuiel",
    hasDropDown: false,
    href: "/",
  },
  {
    title: "nosOffres",

    hasDropDown: true,
    href: "",
    isDown: false,
    dropDown: [
      {
        title: "professionnels",
        links: [
          {
            title: "popcardStandard",
            href: "/commande-popcard-pro-standard",
            image: StandardSrc,
          },
          {
            title: "popcardPersonalise",
            href: "/commande-popcard-pro-personalise",
            image: PersonaliseSrc,
          },
          {
            title: "popcardReviews",
            href: "/commande-popcard-reviews",
            image: ReviewsSrc,
          },
        ],
      },
      {
        title: "enterprise",
        links: [
          {
            title: "popcardMetrics",
            href: "/commande-popcard-entreprise",
            image: MetricsSrc,
          },
          {
            title: "popcardRewards",
            href: "/rewards/commercants",
            image: RewardsSrc,
          },
          {
            title: "coffrets",
            href: "/order#coffrets",
            image: CouffretSrc,
          },
        ],
      },
    ],
  },
  {
    title: "commentCaMarche",
    href: "",
    hasDropDown: true,
    isDown: false,
    dropDown: [
      {
        links: [
          {
            title: "popcard",
            href: "/comment-ca-marche/popcard",
            image: StandardSrc,
          },
          {
            title: "popcardMetrics",
            href: "/comment-ca-marche/metrics",
            image: MetricsSrc,
          },
          {
            title: "popcardRewards",
            href: "/rewards",
            image: RewardsSrc,
          },
        ],
      },
    ],
  },
  {
    title: "contact",
    href: "/contact",
  },
];
const Navbar = () => {
  const { lang, setLang } = useContext<any>(languageContext);
  return (
    <>
      <DesktopNav lang={lang} />
      <MobileNav lang={lang} />
    </>
  );
};

const DesktopNav = ({ lang }: { lang: string }) => {
  return (
    <header className="bg-gray w-full py-4 sticky top-0 left-0 z-[1000] bg-neutral-950 hidden lg:block">
      <Container className="flex justify-between items-center">
        <Link href="/">
          <Image src={PopcardLogo} height={30} alt="Popcard's logo" />
        </Link>
        <nav className="px-4 py-2 rounded-full border-[.3px] flex gap-4 items-center  border-white/20 bg-gradient-to-br from-white/5 to-white/5 ">
          {NAV_LINKS.map(({ href, title, dropDown, hasDropDown }, index) => (
            <NavLink
              href={href}
              title={title}
              key={href + index}
              dropDown={dropDown}
              hasDropDown={hasDropDown}
              lang={lang}
            />
          ))}
          <ButtonLink
            title={navbarTranslation["acheterMaPopcard"][lang]}
            href="/order"
          />{" "}
        </nav>
        <div className="flex items-center gap-4">
          <TransitionLink
            href="/rewards"
            className="rewards-gradient text-white py-2 px-6 hover:opacity-90 transition-opacity rounded-full text-sm"
          >
            Rewards
          </TransitionLink>
          <LanguageToggler />
        </div>
      </Container>
    </header>
  );
};

const MobileNav = ({ lang }: { lang: string }) => {
  const [open, setOpen] = useState(false);
  const [navigation, setNavigation] = useState(NAV_LINKS);

  return (
    <header className="bg-gray w-full py-6 sticky top-0 left-0 z-[1000] bg-neutral-950 block lg:hidden">
      <Container className="flex justify-between items-center">
        <Link href="/">
          <Image src={PopcardLogo} height={25} alt="Popcard's logo" />
        </Link>
        <button onClick={() => setOpen(true)} aria-label="open-navbar">
          <CgMenuRightAlt size={25} className="font-semibold" />
        </button>
      </Container>

      <AnimatePresence>
        {open && (
          <motion.ul
            className={cn(
              "fixed w-screen bg-neutral-950 top-0 left-0 h-screen flex flex-col items-center justify-center gap-8 p-8",
              lang === "ar" && "right-0"
            )}
            initial={{ opacity: 0, height: 0 }}
            animate={{
              opacity: 1,
              height: "100%",
            }}
            exit={{ opacity: 0, height: 0 }}
            transition={{
              type: "just",
            }}
          >
            <li onClick={() => setOpen(false)} aria-label="close-navbar">
              <IoCloseSharp
                size={30}
                className="absolute top-4 right-6 cursor-pointer"
              />
            </li>
            {navigation.map((n, i) => {
              return (
                <li
                  key={i + "nav"}
                  className="cursor-pointer relative"
                  onClick={() => {
                    setNavigation(
                      navigation.map((el, index) => {
                        if (index === i) {
                          el.isDown = !el.isDown;
                        }
                        return el;
                      })
                    );
                  }}
                >
                  <Link
                    href={n.href}
                    className=" flex justify-center items-center"
                    onClick={() => !n.hasDropDown && setOpen(false)}
                  >
                    {n.title && (
                      <span
                        className={cn(
                          n.isDown && n.hasDropDown ? "text-blue-200" : ""
                        )}
                      >
                        {navbarTranslation[n.title][lang]}
                      </span>
                    )}
                    {n.hasDropDown && (
                      <MdKeyboardArrowDown
                        size={20}
                        className={cn(
                          "transition-transform",
                          n.isDown ? "rotate-180 text-blue-200" : ""
                        )}
                      />
                    )}
                  </Link>
                  <AnimatePresence>
                    {n.isDown && (
                      <motion.ul
                        initial={{ height: 0 }}
                        animate={{ height: "auto" }}
                        exit={{ height: 0 }}
                        className="overflow-hidden"
                      >
                        {n?.dropDown?.map((t: any) => {
                          return (
                            <div className="mt-4" key={t.toString()}>
                              {t?.title && (
                                <span className="mb-1 block">
                                  {navbarTranslation[t?.title][lang]}
                                </span>
                              )}
                              <ul className="flex flex-col gap-2">
                                {t.links.map((link: any, index: any) => {
                                  return (
                                    <li
                                      key={link.title + index.toString()}
                                      className="flex items-center gap-1"
                                      onClick={() => setOpen(false)}
                                    >
                                      <MdChevronRight />

                                      <Link
                                        href={link.href}
                                        className="text-sm text-light-gray"
                                      >
                                        {navbarTranslation[link.title][lang]}
                                      </Link>
                                    </li>
                                  );
                                })}
                              </ul>
                            </div>
                          );
                        })}{" "}
                      </motion.ul>
                    )}
                  </AnimatePresence>
                </li>
              );
            })}

            <li className="flex flex-col gap-4 items-center">
              <TransitionLink
                href="/rewards"
                className="rewards-gradient text-white py-2 px-6 hover:opacity-90 transition-opacity rounded-full text-sm"
                onClick={() => setOpen(false)}
              >
                Rewards
              </TransitionLink>
              <LanguageToggler className="ms-0" />
            </li>
          </motion.ul>
        )}
      </AnimatePresence>
    </header>
  );
};
export default Navbar;
